<template>
	<h3 v-if="topicName === 'orthographe'" class="ortho-title">Les parcours d'{{ topicName }}</h3>
	<h3 v-else class="ortho-title">Les parcours de {{ topicName }}</h3>
</template>

<script>
export default {
	name: 'TopicName',
	props: { parcours: Object, topics: Array },
	computed: {
		topicName() {
			if (this.parcours == null || this.topics == null) return '';
			const topic = this.topics.find((t) => t._id === this.parcours.topic_id);
			if (topic == null) return '';
			return topic.name.toLowerCase();
		},
	},
};
</script>

<style lang="scss" scoped></style>
