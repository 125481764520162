<template>
	<div>
		<v-row class="d-flex flex-column justify-center align-center divProfileManagement shadow-blue-not-openned">
			<v-tooltip color="black" top>
				<template v-slot:activator="{ on }">
					<v-btn
						tile
						block
						depressed
						color="white"
						class="firstbutton btn-profile-management"
						:to="{ name: 'Parametres', query: { disableDashboardRedirect: true } }"
						v-on="on"
					>
						<span class="fs12 ortho-text-gray btn-profile-management-title">Paramètres</span></v-btn
					>
				</template>
				<span>Voir les paramètres</span>
			</v-tooltip>
			<v-tooltip color="black" top v-if="profileData.manager">
				<template v-slot:activator="{ on }">
					<v-btn
						tile
						block
						depressed
						color="white"
						class="centralbutton btn-profile-management"
						href="https://admin.orthographiq.com"
						v-on="on"
					>
						<span class="fs12 ortho-text-gray btn-profile-management-title">Console administrateur</span></v-btn
					>
				</template>
				<span>Voir les statistiques utilisateurs</span>
			</v-tooltip>
			<v-tooltip color="black" top>
				<template v-slot:activator="{ on }">
					<v-btn
						tile
						block
						depressed
						color="white"
						class="centralbutton btn-profile-management"
						href="https://docs.google.com/forms/d/e/1FAIpQLSezq8Kkh0Kv1y9o056Zy-5iNVDoBkC8Tf3Lo3HMYGIN4Q3iBg/viewform?usp=sf_link"
						target="_blank"
						v-on="on"
					>
						<span class="fs12 ortho-text-gray btn-profile-management-title">Améliorer la plateforme</span></v-btn
					>
				</template>
				<span>Aide-nous à améliorer la plateforme pour <br />qu'elle ressemble à ce dont tu as besoin.</span>
			</v-tooltip>
			<v-dialog v-model="dialog" max-width="400">
				<template v-slot:activator="{ on }">
					<v-btn tile block depressed color="white" class="lastbutton btn-profile-management" v-on="on">
						<span class="fs12 ortho-text-gray btn-profile-management-title">Déconnexion</span></v-btn
					>
				</template>
				<v-card>
					<div class="fs15 title-container pa-4">
						<h1>À bientôt !</h1>
					</div>
					<v-card-text>Tu es sur le point de te déconnecter d'OrthographIQ.</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="black" text @click="dialog = false">Annuler</v-btn>
						<v-btn dark @click="logout()" color="#F95F62">
							<v-icon>mdi-logout</v-icon>
							<span>Déconnexion</span>
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-row>
	</div>
</template>

<script>
export default {
	name: 'ProfileManagement',
	props: {
		profileData: Object,
	},
	data() {
		return {
			dialog: false,
		};
	},
	methods: {
		logout() {
			this.$store.dispatch('accountManager/logout');
			this.$router.go('/');
		},
	},
};
</script>

<style lang="scss" scoped>
.btn-profile-management {
	&:hover {
		.btn-profile-management-title {
			color: #686bdf !important;
		}
	}
}

.title-container {
	text-align: center;
}
.firstbutton {
	border-bottom: 1px solid #f4f4f4 !important;
	border-top-left-radius: 4px !important;
	border-top-right-radius: 4px !important;
}
.centralbutton {
	border-bottom: 1px solid #f4f4f4 !important;
}
.lastbutton {
	border-bottom-left-radius: 4px !important;
	border-bottom-right-radius: 4px !important;
}
.divProfileManagement {
	border-radius: 4px;
}
</style>
