<template>
	<div class="succes-section d-none">
		<span class="section-title">Succès</span>
		<div class="succes-container">
			<div class="succes">
				<div class="icon-left">
					<v-icon large right color="black">mdi-star</v-icon>
				</div>
				<div class="numbers-right">
					<div class="title-challenge-items">
						<span class="title-challenge mb-3">Tout feu tout flamme</span>
						<span class="title-challenge mb-3">3/7</span>
					</div>
					<v-progress-linear
						value="50"
						height="20"
						class="progess-bar-score-rules"
						rounded
						background-color="#F1F1F1"
					></v-progress-linear>
					<span>Réalise 3 jours d'affilés</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ProfileSuccess',
	data() {
		return {};
	},
	computed: {},
	mounted() {},
	methods: {},
};
</script>

<style lang="scss" scoped>
.succes-section {
	display: flex;
	flex-direction: column;
}

.section-title {
	font-size: 20px;
	font-weight: 700;
	text-align: left;
	margin-top: 20px;
	margin-bottom: 20px;
}

.succes-container {
	display: flex;

	.succes {
		width: 100%;
		padding: 40px;
		border-radius: 8px;
		box-shadow: 0 1px 7px 0 rgba(129, 137, 169, 0.2) !important;
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
	@media screen and (max-width: 600px) {
		display: flex;
		flex-direction: column;

		.succes {
			padding: 20px;
		}
	}
}

.icon-left {
	display: flex;
	flex-direction: row;
	align-items: left;
}
.numbers-right {
	display: flex;
	flex-direction: column;
	align-items: left;
	text-align: left;
	width: 100%;
}

.title-challenge-items {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.title-challenge {
	font-size: 20px;
	font-weight: 700;
}
</style>
