<template>
	<v-card class="mb-4">
		<v-skeleton-loader type="list-item, list-item"></v-skeleton-loader>
	</v-card>
</template>

<script>
export default {
	name: 'ListParcoursSkeleton',
};
</script>

<style lang="scss" scoped></style>
