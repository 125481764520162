<template>
	<div class="text-center open-parcours-modal">
		<div class="cta-div" :class="{ 'cursor-pointer': disableProfileModal != true }" @click="openModal">
			<div class="">
				<div class="avatar-div mb10">
					<div class="avatar-container-img main">
						<div class="avatar-container-img-sub">
							<img class="avatar-img" v-if="profileData.gender == 'M'" src="@/assets/images/profilman.svg" />
							<img class="avatar-img" v-else-if="profileData.gender == 'F'" src="@/assets/images/profilwomen.svg" />
							<img class="avatar-img" v-else src="@/assets/images/profilNoIdentified.svg" />
						</div>
						<v-icon class="iconProfile" :class="{ 'd-none': disableProfileModal }" color="#979FA9">
							mdi-account
						</v-icon>
					</div>
				</div>
			</div>
		</div>

		<!-- Need v-dialog to prevent scrolling -->
		<v-dialog v-model="showModal" persistent></v-dialog>
		<div v-if="showModal" id="modalProfile">
			<v-overlay z-index="300">
				<ProfileModal :profileData="profileData" :formationData="formationData" :closeModal="closeModal" />
			</v-overlay>
		</div>
	</div>
</template>

<script>
import ProfileModal from './ProfileModal';

export default {
	name: 'OpenProfileModal',
	components: {
		ProfileModal,
	},
	props: {
		profileData: Object,
		formationData: Object,
		disableProfileModal: Boolean,
	},
	data() {
		return { showModal: false, dialog: true };
	},
	methods: {
		openModal() {
			if (this.disableProfileModal) return;
			this.showModal = true;

			//mixpanel tracker
			if (process.env.VUE_APP_MIXPANEL == 'production') {
				this.$mixpanel.track('dashboard-profile-goToProfileModal');
			}
		},
		closeModal() {
			this.showModal = false;
		},
	},
};
</script>

<style lang="scss" scoped>
.avatar-div {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 10px;
}

.avatar-container-img {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100px;
	height: 100px;
}

.avatar-img {
	width: 110px;
	height: 110px;
}

.avatar-container-img-sub {
	position: absolute;
}

.iconProfile {
	position: absolute;
	bottom: -5px;
	right: -10px;
}

.open-parcours-modal {
	.cta {
		font-family: 'Lato';
	}
	.cta-div {
		&:hover {
			.cta {
				color: #686bdf !important;
			}
			.iconProfile {
				color: #686bdf !important;
				transform: translateX(30%);
			}
		}
	}
}
</style>
