import moment from 'moment';

export const fakePercentUserDidExercise = () => {
	//fake it until you make it (but fake it well)
	const max = 90;
	const array = [0, 0, 0, 0, 0, 0, 0, 1, 1, 10, 10, 10, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
	const sumArray = array.reduce((a, b) => {
		return a + b;
	}, 0);
	let sumUntilfCursor = 0;

	const minuteInTheDay = moment().clone().startOf('day');
	const diffMinutes = moment().diff(minuteInTheDay, 'minutes');

	let cursor = moment().get('hour'); // hourOfTheDay

	for (let j = 0; j < array.length && j <= cursor; j++) {
		sumUntilfCursor += array[j];
	}

	return Math.round(((diffMinutes * max) / (24 * 60)) * (sumUntilfCursor / sumArray));
};
