var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"d-flex flex-column justify-center align-center divProfileManagement shadow-blue-not-openned"},[_c('v-tooltip',{attrs:{"color":"black","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"firstbutton btn-profile-management",attrs:{"tile":"","block":"","depressed":"","color":"white","to":{ name: 'Parametres', query: { disableDashboardRedirect: true } }}},on),[_c('span',{staticClass:"fs12 ortho-text-gray btn-profile-management-title"},[_vm._v("Paramètres")])])]}}])},[_c('span',[_vm._v("Voir les paramètres")])]),(_vm.profileData.manager)?_c('v-tooltip',{attrs:{"color":"black","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"centralbutton btn-profile-management",attrs:{"tile":"","block":"","depressed":"","color":"white","href":"https://admin.orthographiq.com"}},on),[_c('span',{staticClass:"fs12 ortho-text-gray btn-profile-management-title"},[_vm._v("Console administrateur")])])]}}],null,false,470533209)},[_c('span',[_vm._v("Voir les statistiques utilisateurs")])]):_vm._e(),_c('v-tooltip',{attrs:{"color":"black","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"centralbutton btn-profile-management",attrs:{"tile":"","block":"","depressed":"","color":"white","href":"https://docs.google.com/forms/d/e/1FAIpQLSezq8Kkh0Kv1y9o056Zy-5iNVDoBkC8Tf3Lo3HMYGIN4Q3iBg/viewform?usp=sf_link","target":"_blank"}},on),[_c('span',{staticClass:"fs12 ortho-text-gray btn-profile-management-title"},[_vm._v("Améliorer la plateforme")])])]}}])},[_c('span',[_vm._v("Aide-nous à améliorer la plateforme pour "),_c('br'),_vm._v("qu'elle ressemble à ce dont tu as besoin.")])]),_c('v-dialog',{attrs:{"max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"lastbutton btn-profile-management",attrs:{"tile":"","block":"","depressed":"","color":"white"}},on),[_c('span',{staticClass:"fs12 ortho-text-gray btn-profile-management-title"},[_vm._v("Déconnexion")])])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('div',{staticClass:"fs15 title-container pa-4"},[_c('h1',[_vm._v("À bientôt !")])]),_c('v-card-text',[_vm._v("Tu es sur le point de te déconnecter d'OrthographIQ.")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"black","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Annuler")]),_c('v-btn',{attrs:{"dark":"","color":"#F95F62"},on:{"click":function($event){return _vm.logout()}}},[_c('v-icon',[_vm._v("mdi-logout")]),_c('span',[_vm._v("Déconnexion")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }