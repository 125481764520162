<template>
	<div class="container-btn-progress-bar">
		<a
			v-if="displayCondition"
			class="animate__animated animate__pulse animate__infinite ortho-questions-cours-button activate-formation-button mt-3"
			:href="calendlyLink"
			target="_blank"
		>
			<strong>{{ title }}</strong>
		</a>
	</div>
</template>

<script>
import moment from 'moment';

export default {
	name: 'ActivateFormationEndButton',
	props: {
		formationData: Object,
	},
	computed: {
		title() {
			if (this.formationData.test_trial === '5 trial days') return 'Débloquez toute la formation';
			return 'Faire le bilan';
		},
		calendlyLink() {
			if (this.formationData.test_trial === '5 trial days')
				return 'https://www.orthographiq.com/offres/formation-orthographe?utm_source=application&utm_medium=cta-end-date-feature&utm_campaign=free-trial&utm_id=se-terminee';
			return 'https://meetings-eu1.hubspot.com/rendez-vous-csm?uuid=2e0446cd-e95e-42a1-b311-a21d2b36ba22?utm_source=application&utm_medium=dashboard&utm_campaign=end-date-feature';
		},
		displayCondition() {
			const hasEnded = moment().unix() >= moment(this.formationData.end_date).unix();
			if (hasEnded && this.formationData.formation_finished) return true;
			return false;
		},
	},
};
</script>

<style lang="scss" scoped>
.activate-formation-button {
	width: 100%;
	display: block;
	margin: 0 auto;
	color: white;
}
.container-btn-progress-bar {
	padding-left: 12px;
	padding-right: 12px;
}
</style>
