<template>
	<div class="mt-3 mb-0 text-center open-parcours-modal">
		<div class="cursor-pointer cta-div" @click="openModal">
			<span class="ortho-bold fs12 ortho-text-gray cta" style="color: rgba(0, 0, 0, 0.54)">LES PARCOURS</span>
			<v-icon class="mr3 arrow-right" color="#979FA9" small>mdi-arrow-right</v-icon>
		</div>
		<!-- Need v-dialog to prevent scrolling -->
		<v-dialog v-model="showModal" persistent></v-dialog>
		<div v-if="showModal" id="modalParcours">
			<v-overlay z-index="300">
				<ParcoursModal :closeModal="closeModal" />
			</v-overlay>
		</div>
	</div>
</template>

<script>
import ParcoursModal from './ParcoursModal';

export default {
	name: 'OpenParcoursModal',
	components: { ParcoursModal },
	data() {
		return { showModal: false, dialog: true };
	},
	methods: {
		openModal() {
			this.showModal = true;
		},
		closeModal() {
			this.showModal = false;
		},
	},
};
</script>

<style lang="scss" scoped>
.open-parcours-modal {
	.cta {
		font-family: 'Lato';
	}
	.cta-div {
		&:hover {
			.cta {
				color: #686bdf !important;
			}
			.arrow-right {
				color: #686bdf !important;
				transform: translateX(30%);
			}
		}
	}
}
</style>
