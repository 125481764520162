<template>
	<div>
		<div v-if="displayIcon === 'dont-have'" class="icon-block">
			<v-icon class="color-warning icon" medium>mdi-alert-outline</v-icon>
			<span class="label-desktop color-warning">Non disponible dans votre formation</span>
			<span class="label-mobile color-warning d-none">Non disponible</span>
		</div>
		<div v-else-if="displayIcon === 'locked'" class="icon-block">
			<v-icon class="color-locked icon" medium>mdi-lock</v-icon>
			<span class="label-desktop color-locked">À débloquer</span>
			<span class="label-mobile color-locked d-none">À débloquer</span>
		</div>
		<div v-else-if="displayIcon === 'completed'" class="icon-block block-success">
			<v-icon class="color-success icon" medium>mdi-check-circle-outline</v-icon>
			<span class="label-desktop color-success">Complété</span>
			<span class="label-mobile color-success d-none">Complété</span>
		</div>
		<div v-else-if="displayIcon === 'inprogress'" class="icon-block">
			<v-icon class="color-inprogress icon" medium>mdi-play</v-icon>
			<span class="label-desktop color-inprogress">En cours</span>
			<span class="label-mobile color-inprogress d-none">En cours</span>
		</div>
	</div>
</template>

<script>
import { ParcoursItemStatus } from '../../../../constants/parcours';

export default {
	name: 'ParcoursStatus',
	props: {
		atParcoursId: String,
		formationProgressArr: Array,
	},
	data() {
		return {
			displayIcon: null,
		};
	},
	mounted() {
		this.decideStatusIcon(this.atParcoursId);
	},
	watch: {
		atParcoursId(newVal) {
			this.decideStatusIcon(newVal);
		},
	},
	methods: {
		decideStatusIcon(parcoursId) {
			const formationProgressHaveThisParcoursId = this.formationProgressArr.find((progress) => {
				return progress.config.parcours_list.find((parcoursItem) => {
					return parcoursItem.parcours_id._id === parcoursId;
				});
			});

			if (formationProgressHaveThisParcoursId == null) return (this.displayIcon = 'dont-have'); // If cant find formation progress have this parcours id => user dont own this parcours

			const myParcoursItem = formationProgressHaveThisParcoursId.config.parcours_list.find(
				(parcoursItem) => parcoursItem.parcours_id._id === parcoursId
			);
			if (myParcoursItem == null) return (this.displayIcon = 'dont-have');

			if (
				[
					ParcoursItemStatus.ongoing,
					ParcoursItemStatus.started,
					ParcoursItemStatus.onboarding,
					ParcoursItemStatus.open,
				].includes(myParcoursItem.status)
			)
				this.displayIcon = 'inprogress';
			else if (myParcoursItem.status === ParcoursItemStatus.locked) this.displayIcon = 'locked';
			else if (myParcoursItem.status === ParcoursItemStatus.completed) this.displayIcon = 'completed';
			else this.displayIcon = '';
		},
	},
};
</script>

<style lang="scss" scoped>
.icon-block {
	display: flex;
	align-items: center;
}

.block-success {
	background-color: #41cfb5;
	padding: 8px 13px;
	border-radius: 8px;
	span {
		font-weight: bold;
	}
}

@media screen and (max-width: 600px) {
	.label-desktop {
		display: none;
	}
	.label-mobile {
		display: block !important;
	}
}

.icon {
	margin-right: 3px;
	@media screen and (max-width: 600px) {
		font-size: 30px !important;
	}
}

.color-inprogress {
	color: #7854f7;
}
.color-locked {
	color: #b4b8b8;
}
.color-warning {
	color: #ffa500;
}
.color-success {
	color: white;
}
</style>
