<template>
	<div>
		<div class="mx-auto loader">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				xmlns:xlink="http://www.w3.org/1999/xlink"
				style="margin: auto; background: #fff; display: block"
				width="200px"
				height="200px"
				viewBox="0 0 100 100"
				preserveAspectRatio="xMidYMid"
			>
				<g transform="translate(20 50)">
					<circle cx="0" cy="0" r="6" fill="#000000" transform="scale(0.0426804 0.0426804)">
						<animateTransform
							attributeName="transform"
							type="scale"
							begin="-0.375s"
							calcMode="spline"
							keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
							values="0;1;0"
							keyTimes="0;0.5;1"
							dur="1s"
							repeatCount="indefinite"
						></animateTransform>
					</circle>
				</g>
				<g transform="translate(40 50)">
					<circle cx="0" cy="0" r="6" fill="#000000" transform="scale(0.0550297 0.0550297)">
						<animateTransform
							attributeName="transform"
							type="scale"
							begin="-0.25s"
							calcMode="spline"
							keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
							values="0;1;0"
							keyTimes="0;0.5;1"
							dur="1s"
							repeatCount="indefinite"
						></animateTransform>
					</circle>
				</g>
				<g transform="translate(60 50)">
					<circle cx="0" cy="0" r="6" fill="#000000" transform="scale(0.336105 0.336105)">
						<animateTransform
							attributeName="transform"
							type="scale"
							begin="-0.125s"
							calcMode="spline"
							keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
							values="0;1;0"
							keyTimes="0;0.5;1"
							dur="1s"
							repeatCount="indefinite"
						></animateTransform>
					</circle>
				</g>
				<g transform="translate(80 50)">
					<circle cx="0" cy="0" r="6" fill="#000000" transform="scale(0.68733 0.68733)">
						<animateTransform
							attributeName="transform"
							type="scale"
							begin="0s"
							calcMode="spline"
							keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
							values="0;1;0"
							keyTimes="0;0.5;1"
							dur="1s"
							repeatCount="indefinite"
						></animateTransform>
					</circle>
				</g>
			</svg>
		</div>
	</div>
</template>

<script>
export default {
	name: 'DotLoadingPage',
};
</script>
<style scoped>
.loader {
	top: 20%;
	position: fixed;
	height: 100%;
	background-color: #fff;
	top: 0;
	left: 0;
	display: flex;
	width: 100%;
	z-index: 9;
}
</style>
