<template>
	<div class="parcours-modal-skeleton">
		<v-container>
			<div class="header mt-3">
				<v-btn text icon @click="closeModal">
					<v-icon x-large color="black">mdi-close</v-icon>
				</v-btn>
				<v-skeleton-loader light width="88" type="text"></v-skeleton-loader>
			</div>

			<div class="pm-content">
				<v-skeleton-loader light width="250" type="text"></v-skeleton-loader>

				<div class="p-modal-content">
					<div class="two-cols">
						<div class="left">
							<div>
								<v-skeleton-loader light type="image" width="150"></v-skeleton-loader>
							</div>
						</div>
						<div class="right">
							<v-skeleton-loader light width="80" type="text"></v-skeleton-loader>
							<v-skeleton-loader class="mt-3" light type="text"></v-skeleton-loader>
							<v-skeleton-loader class="mt-2" light width="150" type="text"></v-skeleton-loader>
							<div class="ortho-results-text mt-5">
								<div class="d-flex align-center mt-2">
									<v-skeleton-loader light width="140" type="text"></v-skeleton-loader>
								</div>
								<div class="d-flex align-center mt-2">
									<v-skeleton-loader light width="90" type="text"></v-skeleton-loader>
								</div>
							</div>
						</div>
					</div>

					<div>
						<div class="rules">
							<div class="ruleChip skeleton" style="width: 133px"></div>
							<div v-if="$vuetify.breakpoint.smAndUp" class="ruleChip skeleton" style="width: 157px"></div>
							<div class="ruleChip skeleton" style="width: 133px"></div>
							<div v-if="$vuetify.breakpoint.smAndUp" class="ruleChip skeleton" style="width: 220px"></div>
							<div class="ruleChip skeleton" style="width: 100px"></div>
							<div class="ruleChip skeleton" style="width: 99px"></div>
						</div>
					</div>
				</div>
			</div>
		</v-container>
	</div>
</template>

<script>
export default {
	name: 'ParcoursModalContentSkeleton',
	props: {
		closeModal: Function,
	},
};
</script>

<style lang="scss" scoped>
.parcours-modal-skeleton {
	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 1rem;
		height: 46px;
		min-width: 576px;
		@media screen and (min-width: 960px) {
			margin-bottom: 3rem;
		}
		@media screen and (max-width: 959px) {
			flex-direction: row-reverse;
		}
	}
	.pm-content {
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		@media screen and (min-width: 601px) and (max-width: 959px) {
			width: 80%;
		}
	}

	.p-modal-content {
		.two-cols {
			display: flex;
			justify-content: space-between;
			margin-top: 2rem;

			.left {
				min-height: 250px;
				padding-left: 1.1rem;
			}
			.right {
				text-align: left;
				padding-right: 1.1rem;
				min-width: 306px;
			}
		}

		@media screen and (max-width: 600px) {
			.two-cols {
				display: flex;
				flex-direction: column;
				align-items: center;

				.left {
					min-height: 170px;
				}

				.right {
					text-align: center;
					padding-left: 20px;
					padding-right: 20px;
					opacity: 0;
					height: 50px;
				}
			}
		}

		@media screen and (max-width: 959px) {
			.hide-small-screen {
				display: none;
			}
		}
	}

	.rules {
		display: flex;
		flex-wrap: wrap;
		max-width: 500px;
		justify-content: center;
	}

	.ruleChip {
		border-radius: 8px;
		background-color: #f0f0f0;
		padding: 5px 12px;
		color: black;
		font-family: 'Lato';
		margin-bottom: 8px;
		margin-right: 4px;
		@media screen and (max-width: 600px) {
			padding: 4px 10px;
		}
	}
	.skeleton {
		height: 34px;
	}
}
</style>
