<template>
	<div>
		<div>
			<v-btn v-if="pagination.at !== 1" class="action-btn previous-btn mr-3" @click="previous">Précedent</v-btn>
			<v-btn v-if="pagination.at !== pagination.total" @click="next" class="action-btn">Suivant</v-btn>
		</div>
		<div class="pagination mt-8">
			<span>{{ pagination.at }}/{{ pagination.total }}</span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'NavAndActions',
	props: {
		pagination: Object,
		next: Function,
		previous: Function,
	},
};
</script>

<style lang="scss" scoped>
.action-btn {
	font-size: 17px;
	font-weight: bold;
}
.previous-btn {
	color: #989fa9 !important;
	background: none !important;
	box-shadow: none !important;
}
.pagination {
	font-size: 17px;
	font-weight: bold;
	color: #989fa9;
	@media screen and (max-width: 375px) {
		margin-top: 10px !important;
	}
}
</style>
