<template>
	<div class="mb30 shadow-blue-not-openned formation-end-date-bar-container">
		<v-tooltip color="black" top>
			<template v-slot:activator="{ on }">
				<div v-on="on">
					<v-progress-linear
						:value="valueProgressBar"
						class="formation-end-date-bar-progressbar"
						height="10"
						color="#686bdf"
						background-color="#F1F1F1"
					></v-progress-linear>
				</div>
			</template>
			<span>{{ daysBeforeEndDate }}</span>
		</v-tooltip>
		<div class="mt-2 title-block">
			<span>{{ title }}</span>
			<span>{{ endDate }}</span>
		</div>
		<ActivateFormationEndButton :formationData="formationData" />
	</div>
</template>

<script>
import moment from 'moment';
import ActivateFormationEndButton from './ActivateFormationEndButton.vue';

export default {
	name: 'FormationEndDateProgressBar',
	components: {
		ActivateFormationEndButton,
	},
	props: {
		formationData: Object,
	},
	computed: {
		valueProgressBar() {
			const endDate = moment(this.formationData.end_date);
			const beginDate = moment(this.formationData.begin_date);
			const today = moment();
			if (today.unix() >= endDate.unix()) return 100;
			const diffBegin = endDate.diff(beginDate, 'days');
			const diffToday = today.diff(beginDate, 'days');
			return (diffToday * 100) / diffBegin;
		},
		title() {
			let title = 'Fin de formation : ';
			if (this.formationData.test_trial) return 'Fin de l’essai : ';
			const endDate = moment(this.formationData.end_date).unix();
			const today = moment().unix();
			if (today >= endDate) title = 'Formation terminée : ';
			return title;
		},
		endDate() {
			return moment(this.formationData.end_date).format('DD/MM/YYYY');
		},
		daysBeforeEndDate() {
			const endDate = moment(this.formationData.end_date);
			const today = moment();
			if (today.unix() >= endDate.unix()) return '0 jour restant';
			const diffEnd = endDate.diff(today, 'days');
			return `${diffEnd} jour${diffEnd > 1 ? 's' : ''} restant${diffEnd > 1 ? 's' : ''}`;
		},
	},
};
</script>

<style lang="scss" scoped>
.title-block {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	padding-left: 12px;
	padding-right: 12px;
}
.formation-end-date-bar-container {
	width: 100%;
	border-radius: 4px;
	padding-bottom: 12px;
}
.formation-end-date-bar-progressbar {
	border-radius: 4px 4px 0px 0px !important;
}
</style>
